import Map from "@/model/smoke/CsMap"
import Smoke from "@/model/smoke/Smoke"

export default class GithubService {

    baseUrlJson: string = 'https://raw.githubusercontent.com/asdf-dev/crudDump/refs/heads/cs/maps/base.json'
    baseMapUrl: string = 'https://raw.githubusercontent.com/asdf-dev/crudDump/refs/heads/cs/maps/'

    async getSmokes(): Promise<Map[]> {
        try {
            const response = await fetch(this.baseUrlJson);
            const data: Map[] = await response.json();
            return data
        } catch (error) {
            console.error("Failed to fetch trivia questions:", error);
            throw error;
        }
    }

    async getMap(url: string): Promise<Smoke[]> {
        try {
            //const response = await fetch(`${this.baseUrlJson}${map}/map.json`);
            const response = await fetch(url);
            const data: Smoke[] = await response.json();
            return data
        } catch (error) {
            console.error("Failed to fetch getMap questions:", error);
            throw error;
        }
    }
}
