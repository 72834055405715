import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77a140b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border" }
const _hoisted_2 = { style: {"display":"inline"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "hover-effect" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("strong", null, _toDisplayString(_ctx.site), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.smokes, (smoke) => {
      return (_openBlock(), _createElementBlock("li", _hoisted_2, [
        _createElementVNode("p", {
          onClick: ($event: any) => (_ctx.openDialogBog(smoke))
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(smoke.name), 1)
        ], 8, _hoisted_3)
      ]))
    }), 256))
  ]))
}