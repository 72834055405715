<template>
  <div v-if="smoke" class="container">
    <strong> {{ smoke?.name }}</strong>
    <div id="imageScaleDiv" :style="{ transform: `scale(${imageScalingFactor})`, marginTop: `-${topMargin}px` }">
      <div class="centered" style="font-size: large; padding-left: 5px; padding-right: 5px;">
        <div id="smokepicJumpthrow" v-if="smoke?.jumpThrow">
          <b>Jumpthrow</b>
        </div>
        <div id="smokepicDescription" v-if="smoke?.description">
          {{ smoke.description }}
        </div>
      </div>
      <img v-if="!showHoverImage" :src="imageUrl2" @mouseover="showHoverImage = true"
        style="width: 1050px; height: 820px;" />
      <img v-else :src="imageUrl1" @mouseout="showHoverImage = false" style="width: 1050px; height: 820px;" />
    </div>
    <div v-if="smoke" :style="{ marginTop: `-${topMargin}px` }">
      <span class="clickable-text" @click="imageScale(0.5)">50</span> /
      <span class="clickable-text" @click="imageScale(0.7)">70</span> /
      <span class="clickable-text" @click="imageScale(0.9)">90</span> /
      <span class="clickable-text" @click="imageScale(1)">100</span> /
      <span class="clickable-text" @click="scrollToTop()">top</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import SmokeList from '@/components/SmokeList.vue'
import Smoke from '@/model/smoke/Smoke';

export default defineComponent({
  name: 'Picture',
  props: {
    smoke: Object as () => Smoke,
  },
  data() {
    const imageScalingFactor: number = 1;
    const imageUrl1 = ref('')
    const imageUrl2 = ref('')
    const showHoverImage = ref(false);
    return {
      imageUrl1,
      imageUrl2,
      imageScalingFactor,
      topMargin: 0,
      showHoverImage,
    }
  },
  created() {
    this.imageScalingFactor = this.getStoredImageScale()
    this.setCalculateTopMargin(this.getStoredImageScale() * 100)

    watch(() => this.smoke, (newval, oldval) => {
      if (newval?.throwLocation !== undefined) {
        this.imageRefactor(newval)
      }
    });
  },
  methods: {
    imageRefactor(imageUrl: Smoke) {
      this.imageUrl1 = imageUrl.aimSpot
      this.imageUrl2 = imageUrl.throwLocation
    },
    imageScale(scale: number) {
      localStorage.setItem('imageScalingFactor', scale.toString())
      this.imageScalingFactor = scale

      //update view
      const divToScale = document.getElementById('imageScaleDiv');
      if (divToScale) {
        this.setCalculateTopMargin(scale * 100)
        divToScale.style.transform = `scale(${scale})`;
      }
    },
    setCalculateTopMargin(percentage: number) {
      if (percentage != 100) {
        let amount = ((percentage - 100) / 100) * 820 / 2;
        this.topMargin = -amount
      }
      else {
        this.topMargin = 0
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    getStoredImageScale(): number {
      return parseFloat(localStorage.getItem('imageScalingFactor') || '1')
    }
  },
  components: {
    SmokeList
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#smokeList {
  display: flex;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 85%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.596);
  transform: translate(-50%, -50%);
}

.smokeList {
  flex: 1;
}
</style>
