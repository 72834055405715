<template>
  <div>
    <div id="smokeList" class="flex" style="margin-top: 2%;">
      <SmokeList site="A" v-bind:smokes=aExecute class="smokeList" @openDialog="handleOpenDialog" />
      <SmokeList site="Mid" v-bind:smokes=midExecute class="smokeList" @openDialog="handleOpenDialog" />
      <SmokeList site="B" v-bind:smokes=bExecute class="smokeList" @openDialog="handleOpenDialog" />
    </div>
  </div>
  <div v-if="selectedSmoke !== undefined || selectedSmoke !== null">
    <Picture v-bind:smoke=selectedSmoke! />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType } from 'vue';
import SmokeList from '@/components/SmokeList.vue'
import Picture from '@/components/Picture.vue'
import CsMap from '@/model/smoke/CsMap'
import GithubService from '@/services/GithubService';
import Smoke from "@/model/smoke/Smoke"
import { Area } from "@/model/smoke/Area"
import CacheService from '@/services/CacheService';

export default defineComponent({
  name: 'SmokeBase',
  props: {
    map: {
      type: Object as PropType<CsMap>,
      required: true
    }
  },
setup(props) {
  const cacheService = new CacheService();
  const githubService = new GithubService();

    // Reactive state
    const aExecute = ref<Smoke[]>([]);
    const midExecute = ref<Smoke[]>([]);
    const bExecute = ref<Smoke[]>([]);
    const selectedSmoke = ref<Smoke | null>(null);
    const imageScalingFactor = ref(1);
    const topMargin = ref(0);

        // Function to reset smokes
      const resetSmoke = () => {
      aExecute.value = [];
      midExecute.value = [];
      bExecute.value = [];
    };
    watch(
      () => props.map,
      async (newVal, oldVal) => {
        if (!oldVal || oldVal !== newVal) {
          try {
            const response: Smoke[] = await githubService.getMap(newVal.url);
            resetSmoke(); // Clear previous data

            let id = 1
            for (const it of response) {
              it.id = `${id}`
              id = id + 1
              switch (it?.area) {
                case Area.A:
                  aExecute.value = [...aExecute.value, it];
                  break;
                case Area.MID:
                  midExecute.value = [...midExecute.value, it];
                  break;
                case Area.B:
                  bExecute.value = [...bExecute.value, it];
                  break;
                default:
                  console.error("No area found");
              }
              // Cache images
              try {
                it.aimSpot = await cacheService.cacheImage(it.aimSpot);
              } catch (error) {
                console.error(`Unable to cache for ${it.aimSpot}: ${error}`);
              }
              try {
                it.throwLocation = await cacheService.cacheImage(it.throwLocation);
              } catch (error) {
                console.error(`Unable to cache for ${it.throwLocation}: ${error}`);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      },
      { deep: true, immediate: true }
    );
return {
  selectedSmoke,
  imageScalingFactor,
  topMargin,
  aExecute,
  midExecute,
  bExecute
}

},
  components: {
    SmokeList,
    Picture
  },
  methods: {
    handleOpenDialog(smoke: Smoke) {
      this.selectedSmoke = smoke;
    },
    imageScale(scale: number) {
      localStorage.setItem('imageScalingFactor', scale.toString())
      this.imageScalingFactor = scale

      //update view
      const divToScale = document.getElementById('imageScaleDiv');
      if (divToScale) {
        this.setCalculateTopMargin(scale * 100)
        divToScale.style.transform = `scale(${scale})`;
      }
    },
    setCalculateTopMargin(percentage: number) {
      this.topMargin = (percentage !== 100) ? ((percentage - 100) / 100) * 820 / 2 : 0;
    },
    scrollToEnd() {
      const pageHeight = document.body.scrollHeight;
      window.scrollTo({
        top: pageHeight,
        behavior: 'smooth',
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    getStoredImageScale(): number {
      return parseFloat(localStorage.getItem('imageScalingFactor') || '1')
    },
    resetSmoke(): void {
      this.aExecute = []
      this.midExecute = []
      this.bExecute = []
      this.selectedSmoke = null
    }
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
#smokeList {
  display: flex;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 85%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.596);
  transform: translate(-50%, -50%);
}

.smokeList {
  flex: 1;
}
</style>
