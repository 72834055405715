export default class CacheService {
    async cacheImage(imageUrl: string): Promise<string> {
        const dbName = "imageCacheDB";
        const storeName = "cssmoke";
        const storageKey = `map${imageUrl.toLowerCase()}`;

        // Open IndexedDB
        const db = await openDatabase(dbName, storeName);

        // Check if the image is already cached
        const cachedImage = await getCachedImage(db, storeName, storageKey);
        if (cachedImage) {
            return cachedImage;
        }

        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error("Failed to fetch image");
            }

            const blob = await response.blob();

            // Convert Blob to Base64
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onloadend = async () => {
                    const base64data = reader.result as string;
                    await saveImageToDB(db, storeName, storageKey, base64data);
                    resolve(base64data);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error("Failed to fetch and cache image:", error);
            throw error;
        }


        async function openDatabase(dbName: string, storeName: string): Promise<IDBDatabase> {
            return new Promise((resolve, reject) => {
                const request = indexedDB.open(dbName, 1);

                request.onupgradeneeded = (event) => {
                    const db = (event.target as IDBOpenDBRequest).result;
                    if (!db.objectStoreNames.contains(storeName)) {
                        db.createObjectStore(storeName);
                    }
                };

                request.onsuccess = () => resolve(request.result);
                request.onerror = () => reject(request.error);
            });
        }

        async function getCachedImage(db: IDBDatabase, storeName: string, key: string): Promise<string | null> {
            return new Promise((resolve, reject) => {
                const transaction = db.transaction(storeName, "readonly");
                const store = transaction.objectStore(storeName);
                const request = store.get(key);

                request.onsuccess = () => resolve(request.result || null);
                request.onerror = () => reject(request.error);
            });
        }

        async function saveImageToDB(db: IDBDatabase, storeName: string, key: string, value: string): Promise<void> {
            return new Promise((resolve, reject) => {
                const transaction = db.transaction(storeName, "readwrite");
                const store = transaction.objectStore(storeName);
                const request = store.put(value, key);

                request.onsuccess = () => resolve();
                request.onerror = () => reject(request.error);
            });
        }



    }
}