<template>
  <div id="Toast" v-if="showToast">
    <div class="toast align-items-center fade show centerToast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body toastText">
          cs2igl sockets is closed
        </div>
        <button @click="onToastDismissed" type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast"
          aria-label="Close"></button>
      </div>
    </div>
  </div>

  <div class="home">
    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
        style="margin-top: 0.5%;">
        {{ pickedMap?.name }}
      </button>
      <ul class="dropdown-menu">
        <li v-for="map in maps" :key="map.name">
          <a class="dropdown-item" href="#" @click.prevent="pickMap(map)" @contextmenu.prevent="handleRightClick"
            @click.shift="handleShiftClick">
            {{ map.name }}
          </a>
        </li>
      </ul>
    </div>
    <br>
    <div v-if="pickedMap">
      <SmokeBase v-bind:map="pickedMap" />
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import SmokeBase from '@/components/SmokeBase.vue'
import { CookieService } from "@/services/CookieService";
import GithubService from "@/services/GithubService"
import CsMap from '@/model/smoke/CsMap';

export default defineComponent({
  name: 'HomeView',
  components: {
    SmokeBase
  },
  setup() {
    const githubService = new GithubService()
    const cookieService = new CookieService()
    const pickedMap = ref<CsMap>();
    const maps = ref<CsMap[]>([]);
    const showToast = ref(true)

    const pickMap = (map: CsMap) => {
      pickedMap.value = map;
    };

    const handleRightClick = (event: MouseEvent) => {
      if (event.button === 2) { // 2 corresponds to the right mouse button
        window.location.href = 'https://csstats.gg/';
      }
    };
    const handleShiftClick = (event: MouseEvent) => {
      console.log("missing action")
    }
    return {
      githubService,
      cookieService,
      pickedMap,
      showToast,
      maps,
      pickMap,
      handleRightClick,
      handleShiftClick
    };
  },
  methods: {
    onToastDismissed() {
      this.cookieService.setLocalStorage("toast", "true")
    }
  },
  mounted() {
    const toastValue = localStorage.getItem('toast');
    if (toastValue == 'true') {
      this.showToast = false
    }

    this.githubService.getSmokes().then((response) => {
      this.maps = response
      this.pickedMap = this.maps[0]
    })
      .catch((error) => {
        console.error(`githubService getSmokes error: ${error}`)
      })

  }
});
</script>

<style scoped>
/* Add any component-specific styles here */
.toastText {
  width: 50%;
  margin: auto;
  color: black;
}

.centerToast {
  margin: auto;
  width: 50%;
  padding: 10px;
}
</style>
